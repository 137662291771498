@charset 'utf-8';

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

$jalNan: 'JalnanGothic', sans-serif;

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'thin' {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $breakpoint == 'small' {
    @media (min-width: 390px) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == 'big' {
    @media (min-width: 1920px) {
      @content;
    }
  }
}

// 메인 스와이퍼 비주얼 영역
.mainSwiper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @include respond-to('thin') {
    height: 100vh;
  }
  @include respond-to('big') {
    height: 100vh;
  }

  .overlayVisual {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

// 각 슬라이더 단 스타일링
.swiperSlide {
  position: relative;

  @include respond-to('small') {
    height: 100vh;
  }
  @include respond-to('big') {
    height: 100vh;
  }

  // 스마트폰 목업 이미지 스타일링
  .imgHandle {
    position: absolute;
    left: 0;
    bottom: 10%;
    z-index: 10;

    @include respond-to('thin') {
      display: block;
      width: 85%;
      height: auto;
      left: -10%;
      bottom: -1%;
      z-index: 1;
    }
    @include respond-to('tablet') {
      width: 55%;
      left: -10%;
    }
    @include respond-to('desktop') {
      width: 40%;
      left: -5%;
    }
    @include respond-to('big') {
      width: 27%;
      left: 5%;
      bottom: 0;
    }
  }
  .item {
    width: 100%;
    @include respond-to('thin') {
      height: 100vh;
    }

    @include respond-to('big') {
      height: 100vh;
    }
  }
}

.imageScale {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 10s ease;

  @include respond-to('thin') {
    height: 100vh;
  }
  @include respond-to('big') {
    height: 100vh;
  }
}

// 메인비주얼텍스트 스타일링
.visualDescTitle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  color: white;
  padding: 0 20px;
  
  @include respond-to('thin') {
    top: 25%;
    left: 0;
  }
  @include respond-to('tablet') {
    top: 20%;
    left: 15%;
  }
  @include respond-to('desktop') {
    top: 25%;
    left: 25%;
  }
  @include respond-to('big') {
    top: 50%;
    left: 0;
    bottom: unset;
    transform: translateY(-50%);
  }

  &__descBox {
    z-index: 3;

    .titleText {
      width: 100%;
      margin: 0 auto;

      @include respond-to('thin') {
        width: 90%;
      }
      @include respond-to('small') {
        width: 80%;
      }
      @include respond-to('tablet') {
        width: 90%;
      }
      @include respond-to('big') {
        width: 35%;
      }

      p {
        font-family: $jalNan;
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 2px;
        margin: 10px 0;

        @include respond-to('thin') {
          font-size: 2rem;
        }
        @include respond-to('small') {
          font-size: 26px;
        }
        @include respond-to('tablet') {
          font-size: 45px;
        }
        @include respond-to('big') {
          font-size: 4rem;
        }

        .upText {
          margin-right: 2px;

          @include respond-to('thin') {
            font-size: 2rem;
          }
          @include respond-to('small') {
            font-size: 40px;
          }
          @include respond-to('tablet') {
            font-size: 60px;
          }
          @include respond-to('big') {
            font-size: 4rem;
          }
        }
      }

      .mainVisualTitle {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;

        @include respond-to('tablet') {
          font-size: 26px;
        }
        @include respond-to('big') {
          font-size: 36px;
          line-height: 1.3;
        }
      }
    }
    .text-white {
      margin: 0 auto;
      @include respond-to('thin') {
        width: 90%;
      }
      @include respond-to('small') {
        width: 75%;
      }

      @include respond-to('tablet') {
        width: 90%;
        margin: 0 auto;
      }
      @include respond-to('big') {
        width: 35%;
      }
    }
    .subVisualTitle {
      margin-top: 20px;
      font-size: 34px;
      font-weight: 900;
      @include respond-to('thin') {
        font-size: 1.8rem;
      }
      @include respond-to('small') {
        font-size: 1.5rem;
      }
      @include respond-to('tablet') {
        font-size: 50px;
      }
      @include respond-to('big') {
        font-size: 4rem;
      }
    }
  }
}

// 슬라이더 확대 모션 스타일
.swiper-slide-active .imageScale {
  transform: scale(1.1);
}

// SecondarySlide

.mainSwiper2 {
  width: 50%;
}

.flexSwiperItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

// 스와이퍼 동작 css

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  // margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  width: 30px;
  height: 10px;
  margin: 5px;
  border-radius: 0;
  background: #fff;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  margin-bottom: 20px;
}
